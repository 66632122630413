import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Vernacular = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>Reading: <i>A Vernacular Web</i> by Olia Lialina</h2>
        <br></br>

        <p>Olia Lialina is an Russian artist based in Germany. She has produced early, influential works of network-based art. She writes about digital culture, net art, and web vernacular. You may remember her name from the last reading, in which her project <i>My Boyfriend Came Back From the War</i> (1996) was dicussed.</p>

        <br></br>

        <p>[definition] <i>vernacular</i> -- the language or dialect spoken by the ordinary people in a particular country or region.</p>
        <p>The "language" in our context (the web) is a design language, too, expressed through word, form, color, movement, sound, and more.</p>

        <br></br>
        <p>A series of Lialina's essays analyses the vernacular of the web over time. It comes in three parts. Please read the first two, I believe you will enjoy them:</p>
        <ul>
          <li><a target="_blank" href="http://art.teleportacia.org/observation/vernacular/">A Vernacular Web (2005)</a> (this reading consists of multiple pages, there is a 'next' button at the bottom right)</li>
          <li><a target="_blank" href="http://contemporary-home-computing.org/vernacular-web-2/">A Vernacular Web (2007)</a></li>
        </ul>
        The third part is optional:
        <ul>
          <li><a target="_blank" href="http://contemporary-home-computing.org/prof-dr-style/">"Prof. Dr. Style" / A Vernacular Web (2010)</a></li>
        </ul>


        <br></br>
        <p>Please take notes as you read it and write a short response (150-250). Use your writing to share your opinion, reflection and associations. The following question may inspire your reflection, but are suggestions only:</p>
        <ul>
          <li>Do you find ancestors of the old web vernacular (that Lialina describes) in today's web design?</li>
          <li>How would you describe the research that Lialina is doing? Why does she do it? What is the purpose of it?</li>
          <li>How does the vernacular Lialina describes compare with the web you grew up on / with?</li>
        </ul>

        <br></br>
        <p>1) Complete your writing in a markdown file (.md) inside your repository.</p>
        <p>2) When done, push your work to your GitHub account and <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">submit a link at our class Wiki.</a></p>
      </MiniLayout>
    </Layout>
  )
}



export default Vernacular
